import { type PropsWithChildren } from 'react';
import { Heading, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import { usePassphrasePolicy } from '~/components/passphrase-strength-indicator/use-passphrase-policy';
import { errorColor, goodColor, successColor, weakColor } from '~/components/passphrase-strength-indicator/resources';

type SmallLineProps = PropsWithChildren<{
  color?: string;
  style?: any;
}>;

const SmallLine = ({ style, color, children }: SmallLineProps) => (
  <p
    className="m-0 text-left text-[12px] font-normal leading-[18px] tracking-[-0.25px]"
    style={{
      ...style,
      color,
    }}
  >
    {children}
  </p>
);

type RuleProps = {
  color?: string;
  passed: boolean;
  text: string;
};

const RuleLine = ({ color, passed, text }: RuleProps) => (
  <div className="flex items-center gap-[5px]">
    {passed ? (
      <div className="w-15px">
        <FontAwesomeIcon icon={faCheck} className="h-[15px] w-[15px] text-success" />
      </div>
    ) : (
      <div className="w-15px">
        <FontAwesomeIcon icon={faXmark} className="h-[15px] w-[15px] text-error" />
      </div>
    )}
    {color && (
      <div className="w-[10px]">
        <div className="h-[10px] w-[10px] rounded-full" style={{ backgroundColor: color }} />
      </div>
    )}
    <SmallLine color={passed ? successColor : errorColor}>{text}</SmallLine>
  </div>
);

type Props = {
  open?: boolean;
  triggerRef?: React.RefObject<HTMLInputElement>;
  passphrase: string;
};

export default function PasswordStrengthRulesPopper({ open = false, triggerRef, passphrase }: Props) {
  const { rules } = usePassphrasePolicy({ passphrase });

  return (
    <Popover placement="top" isNonModal isOpen={open} triggerRef={triggerRef}>
      <div className="flex max-w-sm select-none flex-col overflow-y-auto rounded-md border border-solid border-gray-200 bg-white p-4 text-sm text-[#000000de] shadow animate-in fade-in focus:outline-none">
        <Heading
          slot="title"
          className="m-0 pb-[5px] text-left text-[12px] font-semibold leading-[18px] tracking-[-0.25px] text-[#000000d9]"
        >
          Passphrase Requirements
        </Heading>
        <RuleLine passed={rules?.[0].verified || false} text={rules?.[0].message || ''} />
        <SmallLine style={{ padding: '3px 0' }}>For more security use the following rules</SmallLine>
        <RuleLine color={weakColor} passed={rules?.[1].verified || false} text={rules?.[1].message || ''} />
        <RuleLine color={goodColor} passed={rules?.[2].verified || false} text={rules?.[2].message || ''} />
      </div>
    </Popover>

    // <Popper id={id} open={open} anchorEl={anchorEl} placement="top" transition>
    //   {({ TransitionProps }) => (
    //     <Fade {...TransitionProps} timeout={350}>
    //       <Paper sx={{ p: 2, mb: 1, ml: 5, border: 0.5, borderColor: '#f9f9f9' }}>
    //         <p className='m-0 text-left font-semibold text-[12px] leading-[18px] tracking-[-0.25px] text-[#000000d9] pb-[5px]'>
    //           Passphrase Requirements
    //         </p>
    //         <RuleLine passed={rules?.[0].verified || false} text={rules?.[0].message || ''} />
    //         <SmallLine style={{ padding: '3px 0' }}>For more security use the following rules</SmallLine>
    //         <RuleLine color={weakColor} passed={rules?.[1].verified || false} text={rules?.[1].message || ''} />
    //         <RuleLine color={goodColor} passed={rules?.[2].verified || false} text={rules?.[2].message || ''} />
    //       </Paper>
    //     </Fade>
    //   )}
    // </Popper>
  );
}
