import { useState, useRef } from 'react';

import {
  TextField,
  type ValidationResult,
  type TextFieldProps,
  Label,
  Input,
  FieldError,
  Group,
  Button,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import PassphraseStrengthIndicator from '~/components/passphrase-strength-indicator/passphrase-strength-indicator';
import PassphraseStrengthRulesPopper from '~/components/passphrase-strength-indicator/passphrase-strength-rules-popper';

type Props = {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  className?: string;
  showIndicator?: boolean;
  onChangePassphrase?: (value: string) => void;
} & TextFieldProps;

export const PassphraseInput = ({
  name = 'passphrase',
  label = 'Passphrase',
  isDisabled = false,
  showIndicator = false,
  isRequired = true,
  description,
  className,
  errorMessage,
  onChangePassphrase,
  ...props
}: Props) => {
  const [passphrase, setPassphrase] = useState('');
  const [showPassphrase, setShowPassphrase] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLInputElement>(null);

  const handleChangePassphrase = (value: string) => {
    if (value.length > 0 && !isOpen) {
      setIsOpen(true);
    } else if (value.length === 0 && isOpen) {
      setIsOpen(false);
    }

    setPassphrase(value);
    onChangePassphrase?.(value);
  };

  return (
    <div className="flex flex-col">
      <TextField
        {...props}
        ref={triggerRef}
        isDisabled={isDisabled}
        isRequired={isRequired}
        className={twMerge('flex h-auto flex-col', className)}
        onChange={handleChangePassphrase}
        type={showPassphrase ? 'text' : 'password'}
        autoComplete="off"
        aria-label="Passphrase"
        onFocus={() => {
          if (passphrase.length > 0 && !isOpen) {
            setIsOpen(true);
          }
        }}
        onBlur={() => {
          setIsOpen(false);
        }}
      >
        {label && (
          <Label className={twMerge('mb-1 text-left text-xs text-[#262626]', isDisabled && 'opacity-50')}>{`${label} ${
            isRequired ? '*' : ''
          }`}</Label>
        )}
        <Group className="box-border flex h-[44px] rounded-[4px] border border-solid border-[#d9d9d9] pl-[12px] hover:border-primary focus:border-primary">
          <Input className="box-border min-h-[44px] flex-1 rounded-[4px] border-none bg-transparent p-0 text-[14px] text-[#000000d9] focus:ring-0 focus:ring-offset-0" />
          <Group slot="suffix" className="flex w-10 items-center justify-center border-l border-[#d9d9d9]">
            <Button
              isDisabled={passphrase === ''}
              onPress={() => setShowPassphrase(!showPassphrase)}
              className="focus:ring-0 focus:ring-offset-0"
            >
              <FontAwesomeIcon
                icon={showPassphrase ? faEyeSlash : faEye}
                className="h-[15px] w-[15px] text-[#00000066]"
              />
            </Button>
          </Group>
        </Group>
        <FieldError className="mt-1 text-left text-[12px] text-error">{errorMessage}</FieldError>
      </TextField>
      {showIndicator && <PassphraseStrengthRulesPopper open={isOpen} triggerRef={triggerRef} passphrase={passphrase} />}
      {showIndicator && <PassphraseStrengthIndicator passphrase={passphrase} />}
    </div>
  );
};
